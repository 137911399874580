// import logo from "assets/images/brands/brand-main-sm.png";
import logo from "assets/images/brands/logo.png";

export const clientName = "QMMFX";
export const developedBy = "QMMFX";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_URL;
export const companyName =  "QMMFX";
export const sidebarLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
