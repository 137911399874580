// fetch client bank details
export const FETCH_BONUS_REQUESTED = "FETCH_BONUS_REQUESTED";
export const FETCH_BONUS_SUCCESS = "FETCH_BONUS_SUCCESS";
export const FETCH_BONUS_FAIL = "FETCH_BONUS_FAIL";

// add BONUS
export const ADD_BONUS_REQUESTED = "ADD_BONUS_REQUESTED";
export const ADD_BONUS_SUCCESS = "ADD_BONUS_SUCCESS";
export const ADD_BONUS_FAIL = "ADD_BONUS_FAIL";
export const ADD_BONUS_CLEAR = "ADD_BONUS_CLEAR";

// delete BONUS
export const DELETE_BONUS_REQUESTED = "DELETE_BONUS_REQUESTED";
export const DELETE_BONUS_SUCCESS = "DELETE_BONUS_SUCCESS";
export const DELETE_BONUS_FAIL = "DELETE_BONUS_FAIL";

// edit BONUS
export const EDIT_BONUS_REQUESTED = "EDIT_BONUS_REQUESTED";
export const EDIT_BONUS_SUCCESS = "EDIT_BONUS_SUCCESS";
export const EDIT_BONUS_FAIL = "EDIT_BONUS_FAIL";
export const EDIT_BONUS_CLEAR = "EDIT_BONUS_CLEAR";