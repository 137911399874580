import { getClients } from "apis/client";
import SearchableComponent from "pages/Transactions/Forex/internalTransfer/SearchableComponents";
import React from "react";

export default function SearchableSP({
  sp, setSP
}) {


  return (
    <SearchableComponent
      placeholder={("Select SP")}
      label={"SP"}
      name={"sp"}
      onChange={(e) => {
        e?.value ? setSP(e.value) : setSP(e);
      }}
      getData={
        async (payload) => getClients({ 
          payload: {
            type: "LIVE",
            fx: {
              isSp: true
            },
            ...payload
          }
        }).then((res) => (res?.result?.docs || []))
      }
      mapper={(client)=> (
        {
          label: `${client.firstName} ${client.lastName}`,
          value: client
        }
      )}
      value={sp}
    />
  );
}
