import React, { useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { fetchClientsStart } from "store/client/actions";
import AsyncAvFieldSelect from "components/Common/AsyncAvFieldSelect";
import * as clientApi from "apis/client";


const SearchableClientDropdown = (props) => {

  const dispatch = useDispatch();

  const {
    placeholder = "Search",
    isRequired = false,
    name = "client",
    defaultOptions = [],
    value = null,
    clients = [],
    clientData = null,
    onChange = () => { },
    isMulti = false,
  } = props;

  const [clientOptions, setClientOptions] = React.useState(defaultOptions);

  useEffect(() => {
    if (clients && clients.length > 0) {
      setClientOptions(clients.map((client) => ({
        value: client._id,
        label: client.firstName + " " + client.lastName
      })));
    }
  }, [clients, clientData]);

  useEffect(() => {
    if (!clients || clients.length === 0) {
      dispatch(fetchClientsStart({
        limit: 1000,
        page: 1
      }));
    }
  }, []);

  const debouncedChangeHandler = useCallback(
    debounce((inputValue, cb) => {
      clientApi.getClients({
        payload: {
          searchText: inputValue,
          page: 1,
          limit: 1000,
        }
      }).then((data) => {
        return cb(data?.result?.docs.map((client) => (
          {
            label : client.firstName + " " + client.lastName,
            value : `${client._id}`
          }
        )));
      });
    }, 1000), []);

  return (
    <AsyncAvFieldSelect 
      name={name}
      options={clientOptions}
      label={props.t("Client")}
      errorMessage={props.t("Client is required")}
      loadOptions={debouncedChangeHandler}
      defaultOptions={clientOptions || defaultOptions}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
      isMulti={isMulti}
    />
  );
};


const mapStateToProps = (state) => ({
  clients: state.clientReducer.clients || [],
});
export default connect(mapStateToProps, null)(withTranslation()(SearchableClientDropdown));