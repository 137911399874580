import formatDate from "helpers/formatDate";
import moment from "moment";

export const getColumns = (type = "") =>  {
  switch (type) {
    case "commission": 
      return [
        {
          dataField: "id",
          text: "ID",
        },
        {
          dataField: "action",
          text: "Action",
          formatter : (val) => val.action === 0 ? "Buy" : "Sell"
        },
        {
          dataField: "dealId",
          text: "Deal ID",
        },
        {
          dataField: "positionId",
          text: "Position ID",
        },
        {
          dataField: "planType",
          text: "PlanType",
          formatter : (val) => {
            if (val.planType === "1") return "Standard";
            if (val.planType === "2") return "Pro";
            if (val.planType === "3") return "Affiliate Club";
          }
        },
        {
          dataField: "isCopyTrading",
          text: "Trading Type",
          formatter : (val) => {
            if (val?.planType === "3") {
              return val.isCopyTrading ? "Copy Trading" : "Self Trading";
            }
            return "-";
          }
        },
        {
          dataField: "isMaster",
          text: "Master Trader",
          formatter : (val) => {
            return val?.isMaster ? "Yes" : "No";
          }
        },
        {
          dataField: "depth",
          text: "Income Level",
          formatter : (val) => {
            return (val?.depth + 1) * 2;
          }
        },
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "clientLogin",
          text: "Affiliate Login",
        },
        {
          dataField: "volume",
          text: "Volume",
          formatter : (val) => parseInt(val.volume) / 10000
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "time",
          text: "Deal Time",
          formatter: (val) => moment(val.time * 1000).format("DD-MM-YYYY HH:mm:ss"),
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        }
      ];
    case "profit":
      return [
        {
          dataField: "id",
          text: "ID",
        },
        {
          dataField: "action",
          text: "Action",
          formatter : (val) => val.action === 0 ? "Buy" : "Sell"
        },
        {
          dataField: "dealId",
          text: "Deal ID",
        },
        {
          dataField: "positionId",
          text: "Position ID",
        },
        // {
        //   dataField: "isCopyTrading",
        //   text: "Trading Type",
        //   formatter : (val) => {
        //     if (val?.planType === "3") {
        //       return val.isCopyTrading ? "Copy Trading" : "Self Trading";
        //     }
        //     return "-";
        //   }
        // },
        {
          dataField: "isMaster",
          text: "Master Trader",
          formatter : (val) => {
            return val?.isMaster ? "Yes" : "No";
          }
        },
        {
          dataField: "depth",
          text: "Income Level",
          formatter : (val) => {
            return (val?.depth + 1) * 2;
          }
        },
        {
          dataField: "login",
          text: "Login",
        },
        {
          dataField: "clientLogin",
          text: "Affiliate Login",
        },
        {
          dataField: "volume",
          text: "Volume",
          formatter : (val) => parseInt(val.volume) / 10000
        },
        {
          dataField: "profit",
          text: "Amount",
        },
        {
          dataField: "time",
          text: "Deal Time",
          formatter: (val) => moment(val.time * 1000).format("DD-MM-YYYY HH:mm:ss"),
        },
        {
          dataField: "createdAt",
          text: "Added in DB",
          formatter: (val) => formatDate(val.createdAt)
        }
      ];
    default:
      [];
  }
};