import {
  ADD_BONUS_CLEAR,
  ADD_BONUS_FAIL,
  ADD_BONUS_REQUESTED,
  ADD_BONUS_SUCCESS,


  FETCH_BONUS_FAIL,
  FETCH_BONUS_REQUESTED,
  FETCH_BONUS_SUCCESS,


  DELETE_BONUS_REQUESTED,
  DELETE_BONUS_SUCCESS,
  DELETE_BONUS_FAIL,

  
  EDIT_BONUS_REQUESTED,
  EDIT_BONUS_SUCCESS,
  EDIT_BONUS_FAIL,
  EDIT_BONUS_CLEAR
} from "./actionTypes";
  
const initalState = {
  error:"",
  loading:true,
  successMessage:"",
  deleteClearingCounter: 0,
  addClearingCounter: 0,
  editSuccess: false,
};
  
export const bonusReducer = (state = initalState, action)=>{
  switch (action.type){  
    // fetch Bonus details
    case FETCH_BONUS_REQUESTED:
      state = {
        ...state,
        loading: true
      };
      break;
    case FETCH_BONUS_SUCCESS:
      state = { 
        ...state,
        ...action.payload.result,
        success: true,
        error: false,
        loading: false,
        addError: false,
        hasNextPage: action.payload.result.hasNextPage,
        hasPrevPage: action.payload.result.hasPrevPage,
        limit: action.payload.result.limit,
        nextPage: action.payload.result.nextPage,
        page: action.payload.result.page,
        pagingCounter: action.payload.result.pagingCounter,
        prevPage: action.payload.result.prevPage,
        totalPages: action.payload.result.totalPages,
      };
      break;
    case FETCH_BONUS_FAIL:
      state = {
        ...state,
        error: true,
        errorDetails: action.payload.error,
        loading: false
      };
      break;

    case ADD_BONUS_REQUESTED:
      state = {
        ...state,
        addLoading: true
      };
      break;
    case ADD_BONUS_SUCCESS:
      state = {
        ...state,
        addSuccess: true,
        addError: false,
        addLoading: false
      };
      break;
    case ADD_BONUS_FAIL:
      state = {
        ...state,
        addError: true,
        addSuccess: false,
        addErrorDetails: action.payload.error,
        addLoading: false
      };
      break;
    case ADD_BONUS_CLEAR:
      state = {
        ...state,
        addClearingCounter: state.addClearingCounter + 1,
        addSuccess: false,
        addError: false
      };
      break;
      
      // delete Bonus account
    case DELETE_BONUS_REQUESTED:
      state = {
        ...state,
        loading: true,
        deleteLoading: true
      };
      break;
    case DELETE_BONUS_SUCCESS:
      state = {
        ...state,
        success: true,
        fail: false,
        loading: false,
        deleteLoading: false,
        deleteClearingCounter: state.deleteClearingCounter + 1

      };
      break;
    case DELETE_BONUS_FAIL:
      state = {
        ...state,
        success: false,
        fail: true,
        loading:false,
        deleteFailDetails: action.payload.error
      };
      break;
  
      // edit Bonus account
    case EDIT_BONUS_REQUESTED:
      state = {
        ...state,
        loading: true
      };
      break;
    case EDIT_BONUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        editResult: action.payload.result,
        editSuccess: true,
        editError: false
      };
      break;
    case EDIT_BONUS_FAIL:
      state = {
        ...state,
        loading: false,
        editSuccess: false,
        editError: true,
        editErrorDetails: action.payload.error
      };
      break;
    case EDIT_BONUS_CLEAR:
      state = {
        ...state,
        loading: false,
        editError: false,
        editResult: null,
        deleteClearingCounter: 0,
        addClearingCounter: 0,
      
      };
      break;
  
      
    default:
      state = { ...state };
  }
  return state;
};
export default bonusReducer;
