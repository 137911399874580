// fetch trading account by login (use the other one)
export const FETCH_TRADING_ACCOUNTS_REQUESTED = "FETCH_TRADING_ACCOUNTS_REQUESTED";
export const FETCH_TRADING_ACCOUNTS_SUCCESS = "FETCH_TRADING_ACCOUNTS_SUCCESS";
export const FETCH_TRADING_ACCOUNTS_FAIL = "FETCH_TRADING_ACCOUNTS_FAIL";

// fetch all trading accounts
export const FETCH_TRADING_ACCOUNTS_START = "FETCH_TRADING_ACCOUNTS_START";
export const FETCH_TRADING_ACCOUNTS_END = "FETCH_TRADING_ACCOUNTS_END";

export const FETCH_ACCOUNT_TYPES_START = "FETCH_ACCOUNT_TYPES_START";
export const FETCH_ACCOUNT_TYPES_END = "FETCH_ACCOUNT_TYPES_END";

export const LINK_TRADING_ACCOUNT_START = "LINK_TRADING_ACCOUNT_START";
export const CREATE_TRADING_ACCOUNT_START = "CREATE_TRADING_ACCOUNT_START";
export const CREATE_TRADING_ACCOUNT_END = "CREATE_TRADING_ACCOUNT_END";
export const CREATE_TRADING_ACCOUNT_CLEAR = "CREATE_TRADING_ACCOUNT_CLEAR";

export const DELETE_TRADING_ACCOUNT_START = "DELETE_TRADING_ACCOUNT_START";
export const DELETE_TRADING_ACCOUNT_END = "DELETE_TRADING_ACCOUNT_END";
export const DELETE_TRADING_ACCOUNT_CLEAR = "DELETE_TRADING_ACCOUNT_CLEAR";

export const FETCH_TRADING_ACCOUNT_START = "FETCH_TRADING_ACCOUNT_START";
export const FETCH_TRADING_ACCOUNT_END = "FETCH_TRADING_ACCOUNT_END";

export const ADD_ACCOUNT_TYPE_START = "ADD_ACCOUNT_TYPE_START";
export const ADD_ACCOUNT_TYPE_SUCCESS = "ADD_ACCOUNT_TYPE_SUCCESS";
export const ADD_ACCOUNT_TYPE_FAIL = "ADD_ACCOUNT_TYPE_FAIL";

export const UPDATE_ACCOUNT_TYPE_START = "UPDATE_ACCOUNT_TYPE_START";
export const UPDATE_ACCOUNT_TYPE_SUCCESS = "UPDATE_ACCOUNT_TYPE_SUCCESS";
export const UPDATE_ACCOUNT_TYPE_FAIL = "UPDATE_ACCOUNT_TYPE_FAIL";

// fetch trading accounts by login 
export const FETCH_TRADING_ACCOUNT_BY_LOGIN_REQUESTED = "FETCH_TRADING_ACCOUNT_BY_LOGIN_REQUESTED";
export const FETCH_TRADING_ACCOUNT_BY_LOGIN_SUCCESS = "FETCH_TRADING_ACCOUNT_BY_LOGIN_SUCCESS";
export const FETCH_TRADING_ACCOUNT_BY_LOGIN_FAIL = "FETCH_TRADING_ACCOUNT_BY_LOGIN_FAIL";

// fetch trading accounts by customer Id
export const FETCH_TRADING_ACCOUNTS_BY_CUSTOMERID_REQUESTED = "FETCH_TRADING_ACCOUNTS_BY_CUSTOMERID_REQUESTED";
export const FETCH_TRADING_ACCOUNTS_BY_CUSTOMERID_SUCCESS = "FETCH_TRADING_ACCOUNTS_BY_CUSTOMERID_SUCCESS";
export const FETCH_TRADING_ACCOUNTS_BY_CUSTOMERID_FAIL = "FETCH_TRADING_ACCOUNTS_BY_CUSTOMERID_FAIL";

export const UPDATE_LEVERAGE_START = "UPDATE_LEVERAGE_START";
export const UPDATE_LEVERAGE_SUCCESS = "UPDATE_LEVERAGE_SUCCESS";
export const UPDATE_LEVERAGE_FAIL = "UPDATE_LEVERAGE_FAIL";

export const UPDATE_PASSWORD_START = "UPDATE_PASSWORD_START";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const UPDATE_TYPE_START = "UPDATE_TYPE_START";
export const UPDATE_TYPE_SUCCESS = "UPDATE_TYPE_SUCCESS";
export const UPDATE_TYPE_FAIL = "UPDATE_TYPE_FAIL";

export const CHANGE_ACCESS_START = "CHANGE_ACCESS_START";
export const CHANGE_ACCESS_SUCCESS = "CHANGE_ACCESS_SUCCESS";
export const CHANGE_ACCESS_FAIL = "CHANGE_ACCESS_FAIL";

export const GET_OPEN_POSITIONS_START = "GET_OPEN_POSITIONS_START";
export const GET_OPEN_POSITIONS_SUCCESS = "GET_OPEN_POSITIONS_SUCCESS";

export const GET_CLOSE_POSITIONS_START = "GET_CLOSE_POSITIONS_START";
export const GET_CLOSE_POSITIONS_SUCCESS = "GET_CLOSE_POSITIONS_SUCCESS";
